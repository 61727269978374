export default [
  { title: 'Über uns', to: '#!' },
  { title: 'Kontakt', to: '#!' },
  { title: 'Bedingungen', to: '#!' },
  { title: 'Datenschutzrichtlinie', to: '/privacy-policy' },
  { title: 'Impressum', to: '/imprint' },
  { title: 'Preisstrategie', to: '#!' },
  { title: 'Technologiestrategie ', to: '#!' },
  { title: 'kostenlose Probereinigung', to: '#!' },
  { title: 'FAQ', to: '/faq' }
];
