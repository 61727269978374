import illustration1 from 'assets/img/free-electron/download (32).png';
import illustration2 from 'assets/img/free-electron/download (35).png';
import illustration3 from 'assets/img/free-electron/download (41).png';
import illustration4 from 'assets/img/free-electron/download (42).png';
import illustration5 from 'assets/img/free-electron/download (45).png';
import illustration6 from 'assets/img/free-electron/download (46).png';
import illustration7 from 'assets/img/free-electron/download (49).png';
import illustration8 from 'assets/img/free-electron/download (55).png';
import illustration9 from 'assets/img/free-electron/download (58).png';
export default [
  {
    icon: ['far', 'lightbulb'],
    iconText: 'BÜRO',
    color: 'warning',
    title: 'Kreativitätskatalysator',
    description:
      'Sauberkeit im Büro verwandelt den Arbeitsplatz in ein Reich der Inspiration. Sie öffnet die Tür zur Kreativität, lädt den Geist zur Produktivität ein und macht den Raum zum harmonischen Zuhause für Innovation. Wie ein verborgener Zauberstab lässt Sauberkeit den Funken der Leistung in jedem Teammitglied entfachen.',
    image: illustration1
  },
  {
    icon: ['far', 'object-ungroup'],
    iconText: 'FENSTER',
    color: 'warning',
    title: 'Lichtportal',
    description:
      'Reine Fenster sind wie magische Portale, die Licht und Positivität in den Raum fließen lassen. Sie erhellen nicht nur unsere Räume, sondern auch unseren Geist, sie inspirieren und wecken kreative Energie. In ihrem Glanz spiegelt sich die sorgfältige Pflege und Aufmerksamkeit, die wir unserem Umfeld widmen. Saubere Fenster sind das Aushängeschild für Glanz und Transparenz in jedem Gebäude',
    image: illustration2,
    inverse: true
  },
  {
    icon: ['far', 'paper-plane'],
    iconText: 'ZUHAUSE ',
    color: 'warning',
    title: 'Wohlfühloase',
    description:
      'Sauberkeit zu Hause schafft eine magische Atmosphäre des Wohlbefindens und der Geborgenheit. Sie lässt unser Zuhause zur strahlenden Wohlfühloase werden, in der Liebe und Harmonie aufblühen. Inmitten von Reinheit fühlen wir uns inspiriert, energiegeladen und erfüllt. Ein sauberes Zuhause ist das Herzstück des Glücks - ein Spiegel unserer Selbstliebe und des Strebens nach gesundem und erfülltem Leben.',
    image: illustration3
  },
  {
    icon: ['far', 'object-ungroup'],
    iconText: 'GARTEN ',
    color: 'warning',
    title: 'Grünes Paradies',
    description:
      'Sauberkeit im Garten bringt das grüne Paradies zum Leuchten, jede Blüte, jeder Grashalm wird zum strahlenden Akzent in unserer Wohlfühloase. Sie öffnet den Weg zur Schönheit der Natur direkt vor unserer Haustür. Ein sauberer Garten ist wie eine Leinwand, die bereit ist, das Meisterwerk des Lebens zu entfalten. Durch Sauberkeit und Sorgfalt in unserem Garten feiern wir das Leben, ehren wir die Natur und schaffen Raum für Inspiration und Erholung.',
    image: illustration4,
    inverse: true
  },
  {
    icon: ['far', 'paper-plane'],
    iconText: 'TOILETTE',
    color: 'warning',
    title: 'Reinlichkeitsrefugium',
    description:
      'Sauberkeit in der Toilette schafft ein Gefühl von Komfort und Respekt. Es transformiert den kleinsten Raum in unserem Umfeld zu einem Ort der Fülle und des Wohlbefindens. Ein sauberes und gut gepflegtes WC ist ein stiller Zeuge unserer Aufmerksamkeit fürs Detail und unseres Engagements für Hygiene. Es ist der unscheinbare Held, der durch seine Sauberkeit stets für ein Gefühl von Gelassenheit und Zufriedenheit sorgt.',
    image: illustration5
  },
  {
    icon: ['far', 'object-ungroup'],
    iconText: 'BADEZIMMER',
    color: 'warning',
    title: 'Entspannungsoase',
    description:
      'In einem sauberen Badezimmer erleben wir die Magie des Wohlbefindens und der Entspannung. Es ist unsere persönliche Wellnessoase, in der wir den Stress des Tages abspülen und neue Kräfte sammeln. Die glänzenden Armaturen und die strahlend sauberen Fliesen spiegeln das Licht und erhellen unsere Stimmung. Ein sauberes Badezimmer ist ein Ort der Ruhe und des Rückzugs, wo Körper und Seele revitalisiert werden',
    image: illustration6,
    inverse: true
  },
  {
    icon: ['far', 'paper-plane'],
    iconText: 'SCHLAFZIMMER ',
    color: 'warning',
    title: 'Verführungshafen',
    description:
      'Ein sauberes Schlafzimmer ist wie ein stilles Liebesgedicht, das zur Intimität und Erholung einlädt. In der Reinheit des Raumes wird das Bett zur Insel der Träume und des Begehrens. Saubere, duftende Bettwäsche ist wie eine sanfte Liebkosung auf der Haut, welche uns in erholsamen Schlaf wiegt. Sauberkeit im Schlafzimmer ist der unsichtbare Charme, der das Ambiente verwandelt und Zärtlichkeit und Leidenschaft Raum gibt.',
    image: illustration7
  },
  {
    icon: ['far', 'paper-plane'],
    iconText: 'AUFZUG',
    color: 'warning',
    title: 'Vertikales Wunder',
    description:
      'Ein sauberer Aufzug ist wie eine magische Kapsel, die uns bequem und sicher zu unserem Ziel bringt. Die Spiegelung auf glänzenden Metallflächen und das leise Summen der Maschinerie werden zu vertrauten Begleitern unserer Fahrt. Sauberkeit im Aufzug steht für Respekt und Rücksichtnahme, für das Bewusstsein, dass wir diesen Ort mit anderen teilen. Die Magie eines sauberen Aufzugs zeigt sich in jedem glänzenden Knopf und spiegelt die Sorgfalt und das Engagement wider, die in der Instandhaltung unserer Gebäude stecken.',
    image: illustration8,
    inverse: true
  },
  {
    icon: ['far', 'paper-plane'],
    iconText: 'BALKON',
    color: 'warning',
    title: 'Aussichtsplattform der Freude',
    description:
      'Ein sauberer Balkon ist wie eine kleine Zauberwelt im Freien. Hier, hoch über den Dächern oder mit Blick ins Grüne, finden wir unseren persönlichen Freiraum. Sauberkeit verwandelt den Balkon in ein leuchtendes Juwel unseres Zuhauses, einem Ort, an dem wir unseren Gedanken freien Lauf lassen können. Ein sauberer Balkon ist wie ein kleiner Urlaub - eine saubere Erholungsoase, die uns immer wieder aufs Neue begeistert.',
    image: illustration9
  }
];
