export default [
  // { title: 'Webapp', to: '/dashboard/webapp' },
  { title: 'Unterhaltsreinigung', to: '' },
  { title: 'Fassadenreinigung', to: '' },
  { title: 'Glas- und Fensterreinigung', to: '' },
  { title: 'Teppich- und Polsterreinigung', to: '' },
  { title: 'Grundreinigung', to: '' },
  { title: 'Baureinigung', to: '' },
  { title: 'Industriereinigung', to: '' },
  { title: 'Hygienereinigung', to: '' }
];
