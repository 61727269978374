export default [
  {
    title: 'Glänzender Start: Unsere Vision als Gebäudereinigungs-Start-up',
    date: '01.01.2024',
    read: '7 min',
    to: '/blog'
  },
  {
    title:
      'Die Bedeutung von Sauberkeit: Wie Reinigungsdienstleistungen Ihr Geschäft beeinflussen',
    date: 'coming soon',
    read: '3min',
    star: true
  },
  {
    title:
      'Unsere Stadt Bochum: Ein Überblick über die Gebäude, die wir zum Strahlen bringen',
    date: 'coming soon',
    read: '2min'
  },
  {
    title: 'Tipps für den Frühjahrsputz: So halten Sie Ihr Gebäude in Topform',
    date: 'coming soon',
    read: '10min'
  }
];
