import React from 'react';
import blogList from './blogList';
import Section from 'components/common/Section';
import Process from './Process';

import { isIterableArray } from 'helpers/utils';
import SectionHeader from '../landing/SectionHeader';

const Processes = () => (
  <Section>
    <SectionHeader
      title="Miss Clean - Ihr Kompetenzzentrum für makellose Sauberkeit. Wir bringen Glanz in jeden Winkel Ihrer Welt!"
      subtitle="Professioneller Gebäudereinigungsservice mit Herz und Verstand - Für Räume, die sich so gut anfühlen wie sie aussehen!"
    />
    {isIterableArray(blogList) &&
      blogList.map((process, index) => (
        <Process key={process.color} isFirst={index === 0} {...process} />
      ))}
  </Section>
);

export default Processes;
