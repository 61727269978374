export const strings = {
  en: {
    'Tomorrow will be too late!': 'Tomorrow will be too late!',
    leadText:
      'Benefit from our exclusively designed solutions to digitally transform your business!',

    Efficiency: 'Efficiency',
    Productivity: 'Productivity',
    Saving: 'Saving',
    perfection: 'perfection',
    Innovation: 'Innovation',
    motivation: 'motivation',
    Satisfaction: 'Satisfaction',
    Security: 'Security',

    'to your company': 'to your company'
  },
  de: {
    'Tomorrow will be too late!': 'Morgen ist es zu spät!',
    leadText:
      '"Sauberkeit ist nur einen magischen Klick entfernt! Entdecken Sie die bezaubernde Welt der Perfektion im Reinigen mit dem Miss Clean Team! Ihr strahlend sauberes Glück liegt nur einen Klick entfernt!"',

    Efficiency: 'Effizienz ',
    Productivity: 'Produktivität',
    Saving: 'Einsparung ',
    perfection: 'Perfektion',
    Innovation: 'Innovation',
    motivation: 'Motivation',
    Satisfaction: 'Zufriedenheit',
    Security: 'Sicherheit',
    'to your company': 'zu Ihrem Unternehmen'
  }
};
