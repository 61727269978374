export default [
  {
    description:
      '"Als Inhaber eines großen Bürokomplexes im Zentrum von Bochum, betrauen wir \'Gebäudereinigung Bochum\' schon seit Jahren mit der Reinigung unserer Räumlichkeiten. Wir sind immer wieder beeindruckt von der akribischen Sauberkeit und der Professionalität, die sie an den Tag legt. Zudem sind sie immer zuverlässig und erledigen ihre Arbeit unauffällig und gründlich." ',
    author: ' – Johannes Schmidt'
  },
  {
    description:
      '"Die \'Gebäudereinigung Bochum\' hat uns noch nie enttäuscht. Sie übertrifft stets unsere Erwartungen mit einer gründlichen, effizienten Reinigung und einer bewundernswerten Detailgenauigkeit. Als Leiterin der Operations-Abteilung in einer beschäftigten Werbeagentur hier in Bochum, schätze ich ihren fachkundigen Service sehr." ',
    author: ' – Sarah Müller, Operations Managerin'
  },
  {
    description:
      '"Das Team von \'Gebäudereinigung Bochum\' hat ein ausgezeichnetes Auge für Details. Sie stellen sicher, dass unsere Büroräume immer in einwandfreiem Zustand sind, was einen großen Unterschied für unser Team und unsere Kunden macht. Ich würde sie ohne zu zögern weiterempfehlen." ',
    author: '– Tim Neumann, Inhaber.\n' + '\n'
  }
];
