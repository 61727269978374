import Section from 'components/common/Section';
import AppContext from 'context/Context';
import React, { useContext, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Typewriter } from 'react-simple-typewriter';
import missClean from 'assets/img/generic/13.png';
import { I18n } from '@aws-amplify/core';
import { FiCalendar } from 'react-icons/fi';
import CreateAppointment from '../appointment/CreateAppointment';

const Hero = () => {
  const [isOpenAppointmentModal, setIsOpenAppointmentModal] = useState(false);

  const [appointmentDate, setAppointmentDate] = useState();
  const {
    config: { isRTL }
  } = useContext(AppContext);

  return (
    <>
      <Section className="py-0 overflow-hidden light">
        <Row className="justify-content-center align-items-center pt-8 pt-lg-10 pb-lg-9 pb-xl-0">
          <Col
            md={11}
            lg={8}
            xl={4}
            className="pb-7 pb-xl-9 text-center text-xl-start"
          >
            <h1 className="text-white fw-light">
              Wir bescheren <br />
            </h1>
            <h1 className="text-white fw-light">
              <span className="fw-bold ">
                <Typewriter
                  words={[
                    I18n.get('makellosen Glanz'),
                    I18n.get('strahlende Sauberkeit')
                  ]}
                  loop={false}
                  cursor={!isRTL ? true : false}
                  cursorStyle="|"
                  cursorColor="#AEE29D"
                  typeSpeed={40}
                  deleteSpeed={40}
                  delaySpeed={2000}
                />
              </span>
              <br />
              {I18n.get('in Ihren Gebäuden.')}
            </h1>

            <p className="lead text-white opacity-75">{I18n.get('leadText')}</p>

            {/*<Button*/}
            {/*  as={Link}*/}
            {/*  to="/dashboard/webapp"*/}
            {/*  variant="outline-light"*/}
            {/*  size="lg"*/}
            {/*  className="border-2 rounded-pill mt-4 fs-0 py-2"*/}
            {/*>*/}
            {/*  {I18n.get('Tomorrow will be too late!')}*/}
            {/*  <FontAwesomeIcon*/}
            {/*    icon="play"*/}
            {/*    transform="shrink-6 down-1 right-5"*/}
            {/*  />*/}
            {/*</Button>*/}
            <Button
              type="button"
              onClick={() => {
                setIsOpenAppointmentModal(!isOpenAppointmentModal);
              }}
              variant="outline-warning"
              size="lg"
              className="border-2 rounded-pill  py-2 fs-2"
            >
              kostenlose Probereinigung{' '}
              <FiCalendar className="text-warning fs-2" />
            </Button>
            <CreateAppointment
              isOpenAppointmentModal={isOpenAppointmentModal}
              setIsOpenAppointmentModal={setIsOpenAppointmentModal}
              appointmentDate={appointmentDate}
              setAppointmentDate={setAppointmentDate}
            />
          </Col>
          <Col
            xl={{ span: 7, offset: 0 }}
            className="align-self-end mt-4 mt-xl-0"
          >
            <Link to="/" className="img-landing-banner">
              <img className="img-fluid" src={missClean} alt="" />
            </Link>
          </Col>
        </Row>
      </Section>
    </>
  );
};

export default Hero;
