export const strings = {
  en: {
    lead1:
      'We strongly believe that digitalization of company processes can be a tremendous asset to your business. Our innovative services enable business leaders to quickly deploy digital initiatives that improve performance, reduce costs, and streamline processes.',
    lead2:
      ' Through our intuitive cloud services, you can optimize and automate workflow, access meaningful data insights, manage resources, and stay agile in a rapidly changing market. With our end-to-end digitalization solutions, you can skyrocket your business efficiency and success. ',
    lead3:
      'Start your transformation journey with us today and unlock the potential of digitalization.',
    Company: 'Company'
  },
  de: {
    lead1:
      'Willkommen bei uns - Ihrer motivierten Gebäudereinigungs-Start-up in Bochum und Umgebung! Wir sind mehr als nur ein Reinigungsdienst, wir sind Ihr Partner, der Ihr Gebäude zum Strahlen bringt. Unsere Mission ist es, jeden Raum in eine Wohlfühloase zu verwandeln, und wir tun dies mit Leidenschaft, Sorgfalt und höchsten Qualitätsstandards. In jedem Winkel, den wir reinigen, in jedem Glanz, den wir hinterlassen, sehen Sie unser Engagement und unser Streben, Ihnen den bestmöglichen Service zu bieten. Denn wir glauben, Sauberkeit ist mehr als nur eine äußere Erscheinung - sie ist das Spiegelbild von Hingabe, Professionalität und Aufmerksamkeit fürs Detail. Treten Sie ein in die Welt der makellosen Räume und erleben Sie die Magie der Sauberkeit! ',
    lead2:
      'Mit unseren intuitiven Cloud-Diensten können Sie den Arbeitsablauf optimieren und automatisieren, auf bedeutsame Dateneinblicke zugreifen, Ressourcen verwalten und in einem sich schnell ändernden Markt agil bleiben.',
    lead3:
      ' Mit unseren End-to-End-Digitalisierungslösungen können Sie die Effizienz und den Erfolg Ihres Unternehmens in die Höhe treiben.',
    Company: 'Firma'
  }
};
