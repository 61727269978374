import illustration1 from './blog-asset/1.png';
import illustration2 from './blog-asset/2.png';
import illustration3 from './blog-asset/3.png';
import illustration4 from './blog-asset/4.png';
import illustration5 from './blog-asset/5.png';
import illustration6 from './blog-asset/6.png';
import illustration7 from './blog-asset/7.png';
import illustration8 from './blog-asset/8.png';
import illustration9 from './blog-asset/9.png';
import illustration10 from './blog-asset/10.png';

export default [
  {
    color: 'warning',
    title: 'Schulen',
    description:
      'Wir sorgen für saubere und gesunde Lernumgebungen. Ein optimaler Ort für Kinder.',
    image: illustration1
  },
  {
    color: 'warning',
    title: 'Private Häuser',
    description:
      'Professionelle Reinigungsdienste für Ihr Zuhause, damit Sie mehr Zeit für das Wesentliche haben.',
    image: illustration2
  },
  {
    color: 'warning',
    title: 'Krankenhäuser',
    description:
      'Nicht nur sauber, sondern hygienisch rein. Damit das Wohl Ihrer Patienten im Vordergrund steht.',
    image: illustration3
  },
  {
    color: 'warning',
    title: 'Bürogebäude',
    description:
      'Ein sauberes Arbeitsumfeld steigert die Produktivität. Lassen Sie uns für die Sauberkeit sorgen!',
    image: illustration4
  },
  {
    color: 'warning',
    title: 'Hotels',
    description:
      'Für das erstklassiges Ambiente Ihrer Hotels. Sauberkeit und Hygiene, über die Ihre Gäste sprechen werden.',
    image: illustration5
  },
  {
    color: 'warning',
    title: 'Einzelhandelsgeschäfte',
    description:
      'Ein sauberer Laden zieht Kunden an. Verlassen Sie sich auf uns für strahlende Ergebnisse.',
    image: illustration6
  },
  {
    color: 'warning',
    title: 'Gym',
    description:
      'Hygienische Fitness-Studios für Ihre Gesundheit und Sicherheit.',
    image: illustration7
  },
  {
    color: 'warning',
    title: 'Restaurants',
    description:
      'Sauberkeit ist genauso wichtig wie gutes Essen. Für ein tolles Esserlebnis!',
    image: illustration8
  },
  {
    color: 'warning',
    title: 'Altenheime',
    description:
      'Sorgenfreie Reinigung für die Sicherheit und Wohlbefinden Ihrer älteren Angehörigen.',
    image: illustration9
  },
  {
    color: 'warning',
    title: 'Flughäfen',
    description:
      'Für eine saubere und angenehme Reiseerfahrung. Von der Abreise bis zur Ankunft.',
    image: illustration10
  }
];
